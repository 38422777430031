<template>
  <div class="page">
    <!--导航-->
    <!-- <div class="nav bgf flexcenter">
      <img class="logo" src="~images/icon/logo.png">
      <span>途够心选</span>
    </div> -->
  
    <div class="container">
      <!--搜索栏-->
      <van-search v-model="searchValue" @click="goSearch" placeholder="请输入您要搜索的商品名" />
    
      <!--banner栏-->
      <div class="banner-box">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#FFD11A">
          <van-swipe-item  v-for="item in bannerList" :key="item.id">
            <div class="swiper-item">
              <router-link :to="item.v_link" class="item">
			  <img class="ban" :src="item.v_banner">
			  </router-link>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    
      <div class="gap"></div>
    
      <!--渠道栏-->
      <div class="channel-box bgf flexcenter">
		<div class="item" v-for="item in menusList" :key="item.id">
		 <router-link :to="item.v_link" class="item">
		  <img class="full-img" mode="widthFix" :src="item.v_icon">
		  <div class="channel-txt">{{item.v_name}}</div>
		  </router-link>
		</div>
      </div>
    
      <div class="gap"></div>
    
      <!--北京专区-->
      <div class="zone-box bgf flexcenter">
        <div class="item" v-for="item in zoneOne" :key="item.id">
          <router-link :to="item.v_link" class="item">
		  <img class="full-img" mode="widthFix" :src="item.v_img">
		  </router-link>
        </div>
      </div>
    
      <div class="gap"></div>
    
      <!--直播栏-->
      <div class="live-box bgf flexcenter">
		<router-link :to="zoneTwo[0]['v_link']" class="item">
        <img class="live-img" mode="widthFix" :src="zoneTwo[0].v_img">
		</router-link>
      </div>
	
      <div class="gap"></div>
    
      <!--特色活动-->
      <div class="activity-box bgf flexcenter">
        <div class="left">
          <router-link :to="zoneThir[0]['v_link']" hover-class="navigator-hover">
            <img class="full-img" mode="widthFix" :src="zoneThir[0]['v_img']">
          </router-link>
        </div>
        <div class="right">
		  <router-link :to="zoneThir[1]['v_link']" hover-class="navigator-hover">
			<img class="full-img up" mode="widthFix" :src="zoneThir[1]['v_img']">
		  </router-link>
		  <router-link :to="zoneThir[2]['v_link']" hover-class="navigator-hover">
			<img class="full-img" mode="widthFix" :src="zoneThir[2]['v_img']">
		  </router-link>
        </div>
      </div>
    
      <div class="gap"></div>
    
      <!--好物推荐-->
      <div class="haowu-box bgf">
        <div class="head flexcenter" v-if="goodsBox.length>0">
          <div class="left flexcenter">
            <div class="line"></div>
            <div class="">{{goodsBox[0]['v_title']}}</div>
          </div>
          <div class="right">
            <!-- <i class="iconfont icongengduo"></i> -->
          </div>
        </div>
        <div class="cont flexcenter">
          <router-link :to="{name: 'product-detail', query: {gid: item.goods_id}}" class="item" v-for="item in goodsBoxGlist" :key="item.goods_id">
            <img class="full-img" mode="widthFix" :src="item.default_image">
            <div class="name">{{item.goods_name}}</div>
            <div class="price">￥{{item.price}}</div>
          </router-link>
        </div>
      </div>
    
      <div class="gap"></div>
    
      <!--商品列表-->
      <div class="list-box bgf">
        <div class="head flexcenter">
          <div v-for="(item, idx) in goodsTabItems" :key="item.id" :class="['item', {active: activeListTab === idx}]" @click="toggleTab(idx)">
            <div class="name">{{item.v_name}}</div>
            <div class="desc">{{item.v_desc}}</div>
          </div>
        </div>
        <div class="cont">
          <!-- <div > -->
		  <router-link :to="{name: 'product-detail', query: {gid: item.goods_id}}" class="item flexcenter" v-for="(item, idx) in goodsTabCurrent" :key="item.goods_id">
			<div class="left">
              <img class="full-img" mode="widthFix" :src="item.default_image">
            </div>
            <div class="middle">
              <div class="name ellipsis">{{item.brand_name}}</div>
              <div class="desc ellipsis">{{item.goods_name}}</div>
              <div class="price-huiyuan">心选价:￥{{item.front_price}}</div>
              <div class="price">市场价: <span class="price-txt">￥{{item.marking_price}}</span></div>
            </div>
            <div class="right">
              <i class="iconfont iconjia2 add"></i>
            </div>
		  </router-link>
          <!-- </div> -->
        </div>
      </div>
  
    </div>
	
	<van-tabbar route active-color="#FF8A00">
	  <van-tabbar-item replace to="/" icon="home-o">首页</van-tabbar-item>
	  <van-tabbar-item replace to="/products" icon="search">商品</van-tabbar-item>
	  <van-tabbar-item replace to="/cart" icon="cart-o">购物车</van-tabbar-item>
	  <van-tabbar-item replace to="/myinfo" icon="manager-o">我的</van-tabbar-item>
	</van-tabbar>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    data() {
      return {
        searchValue: '',
        activeListTab: 0,
        bannerList: [{
          // pic: require('~images/index/banner1.png'),
          id: 1,
        },{
          // pic: require('~images/index/banner1.png'),
          id: 2,
        }],
        menusList:[],
        zoneOne:[],
        zoneTwo:[{v_img:'',v_link:''}],
        zoneThir:[{v_img:'',v_link:''},{v_img:'',v_link:''},{v_img:'',v_link:''}],
        goodsBox:[],
        goodsBoxGlist:[],
        goodsTabItems:[],
        goodsTabCurrent:[],
      }
    },
    created() {
      this.getList();
    },
    methods: {
      getList() {
        this.post(this.API.index, {})
          .then(response => {
            this.bannerList = response.data.banner
            this.menusList = response.data.menus
            this.zoneOne = response.data.zoneOne
            this.zoneTwo = response.data.zoneTwo
            this.zoneThir = response.data.zoneThir
            this.goodsBox = response.data.goodsBox
            this.goodsBoxGlist = response.data.goodsBoxGlist
            this.goodsTabItems = response.data.goodsTabItems
            this.goodsTabCurrent = response.data.goodsTabItems[0]['goodsList']
          })
      },
      toggleTab(tab){
        this.activeListTab = tab
        this.goodsTabCurrent = this.goodsTabItems[tab]['goodsList']
      },
      goSearch(){
        this.$router.push({
          name: 'products'
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  /**index.wxss**/
  .page {
    background-color: #F5F5F5;
    height: 100%;
    font-size: 32px;
    line-height: 1.6;
    font-family: "PingFang SC";
  }
  
  /**导航**/
  .nav {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;
    padding-left: 30px;
    display: flex;
    align-items: center;
  }
  .logo {
    margin-right: 8px;
    width: 36px;
    border-radius: 50%;
  }
  .title {
    font-size: 32px;
    font-weight: bold;
    color: #191919;
  }

  .my-swipe .van-swipe-item {
    color: #fff;
    text-align: center;
  }
  
  .container {
	padding-bottom: 120px;
  }
  
  /*搜索区*/
  
  /*banner区*/
  .banner-box {
    // height: 486px;
	line-height: 1px;
  }
  .ban {
    width: 100%;
  }
  
  .gap {
    height: 20px;
  }
  
  /*渠道区*/
  .channel-box {
    justify-content: space-between;
    padding: 0 30px;
    height: 188px;
  }
  .channel-box .item {
    width: 120px;
    text-align: center;
  }
  .channel-txt {
    font-size: 24px;
    font-weight: bold;
    color: #777777;
  }
  
  /*北京区*/
  .zone-box {
    justify-content: space-between;
    padding: 0 30px;
    height: 210px;
  }
  .zone-box .item {
    width: 334px;
  }
  
  /*直播位图*/
  .live-box {
    justify-content: center;
    height: 210px;
  }
  .live-img {
    display: block;
    width: 694px;
  }
  
  /*特色活动*/
  .activity-box {
    justify-content: space-between;
    padding: 0 30px 0 24px;
    height: 370px;
  }
  .activity-box .left {
    width: 309px;
  }
  .activity-box .right {
    width: 360px;
  }
  
  .activity-box .right .up{
    margin-bottom: 12px;
  }
  
  /*好物推荐*/
  .haowu-box {
    padding: 20px 30px 0 30px;
  }
  .haowu-box .head {
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 2px solid #EEEEEE;
  }
  .haowu-box .head .left {
    font-size: 28px;
    font-weight: bold;
  }
  .haowu-box .head .left .line{
    width: 6px;
    height: 32px;
    background-color: #FFC602;
    margin-right: 16px;
  }
  .haowu-box .cont {
    justify-content: space-between;
    height: 244px;
  }
  .haowu-box .cont .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 12px 0 20px 0;
    width: 153px;
    text-align: center;
    text-indent: -14px;
  }
  .haowu-box .cont .item .name{
    font-size: 24px;
	width: 140px;
	height: 42px;
	padding-left: 15px;
	overflow: hidden;
    color: #333333;
  }
  .haowu-box .cont .item .price{
    font-size: 28px;
    font-weight: bold;
    color: #F84F41;
  }
  
  /*商品列表*/
  .list-box {
    padding: 0 30px;
  }
  .list-box .head{
    justify-content: space-between;
    height: 106px;
    padding-bottom: 10px;
    border-bottom: 2px solid #EEEEEE;
  }
  .list-box .head .item{
    width: 173px;
    text-align: center;
  }
  .list-box .head .item .name {
    font-size: 28px;
    color: #333333;
  }
  .list-box .head .item .desc {
    font-size: 24px;
    color: #9B9B9B;
  }
  .list-box .head .active .name {
    font-weight: bold;
  }
  .list-box .head .active .desc {
    border-radius: 16px;
    background: -webkit-linear-gradient(right, #FF9D10, #FCCE09);
    color: #FFFFFF;
  }
  .list-box .cont{
  }
  .list-box .cont .item {
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 2px solid #EEEEEE;
  }
  .list-box .cont .item .left {
    width: 199px;
  }
  .list-box .cont .item .middle {
    width: 335px;
  }
  .list-box .cont .item .middle .name {
    font-size: 28px;
    font-weight: bold;
    color: #191919;
  }
  .list-box .cont .item .middle .desc {
    margin-bottom: 10px;
    font-size: 24px;
    color: #777777;
  }
  .list-box .cont .item .middle .price {
    font-size: 30px;
    font-weight: bold;
    color: #F84F41;
  }
  .list-box .cont .item .middle .price .price-txt{
    font-size: 24px;
    color: #F84F41;
    display: inline;
    text-decoration: line-through;
  }
  .list-box .cont .item .middle .price-huiyuan {
    display: inline-block;
    padding: 2px 10px;
    font-size: 24px;
    color: #FEA30F;
    border: 2px solid #FEA30F;
    border-radius: 6px;
  }
  .list-box .cont .item .right {
    display: flex;
    width: 100px;
    height: 177px;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .list-box .cont .item .right .add {
    font-size: 40px;
  }
  .van-search {
	  padding:20px 10px;
  }
  .van-search__content {
    border-radius: 44px!important;
  }
</style>
